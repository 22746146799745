<template>
  <div class="module">
    <div class="container">
      <separator class="separator is-primary" v-if="separatorVisible"></separator>
      <h1 class="rzecommerce-title mb-4" v-if="isRzecommerce2024Event">
        {{ $t("custom.rzecommerce_mobile_app_title") }}
      </h1>
      <h2 class="title is-2 has-text-centered mb-2" v-else>
        {{ $t("mobile_app_info.title") }}
      </h2>
      <div class="columns is-vcentered is-centered is-mobile">
        <div class="column is-half is-hidden-mobile" v-if="previewImageVisible">
          <div class="has-text-centered">
            <img :src="previewImageUrl" style="max-height: 400px"/>
          </div>
        </div>
        <div class="column is-half">
          <div v-if="customDescription"
               class="is-size-5 content has-text-weight-semibold has-text-centered mb-4 is-whitespaced">
            <div v-html="customDescription"></div>
          </div>
          <div v-else>
            <p class="content is-size-5 mb-2">
              <span class="has-text-primary has-text-weight-bold" :class="{ 'is-rzecommerce-2024-event': isRzecommerce2024Event }">1.&nbsp;</span>
              <i18n path="mobile_app_info.about" tag="span" class="description-text has-text-weight-semibold is-whitespaced" :class="{ 'is-rzecommerce-2024-event': isRzecommerce2024Event }">
                <strong class="description-text" :class="{ 'is-rzecommerce-2024-event-secondary': isRzecommerce2024Event }">{{ appName }}</strong>
              </i18n>
            </p>
            <p class="content is-size-5 mb-4">
              <span class="has-text-primary has-text-weight-bold" :class="{ 'is-rzecommerce-2024-event': isRzecommerce2024Event }">2.&nbsp;</span>
              <span class="description-text has-text-weight-semibold is-whitespaced" :class="{ 'is-rzecommerce-2024-event': isRzecommerce2024Event }">
                {{ $t("mobile_app_info.scanner") }}
              </span>
            </p>
          </div>

          <div class="columns is-variable is-1 is-mobile is-centered is-vcentered">
            <div class="column is-narrow">
              <figure class="box image is-170x170 px-0 py-0">
                <img :src="qrCodeImageUrl" alt="qrCode"/>
              </figure>
            </div>
            <div class="column is-narrow-mobile">
              <a :href="appleStoreUrl" class="is-block ml mb-3" target="_blank">
                <apple-store-button class="app-store-button"></apple-store-button>
              </a>
              <a :href="googlePlayUrl" class="is-block" target="_blank">
                <google-play-button class="google-play-button"></google-play-button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Separator from "@/web/components/shared/Separator";
import PreviewImage from "@/assets/mobile_app_info_phones_default.png";
import AppleStoreButton from "@/web/components/mobileapp/buttons/AppleStoreButton";
import GooglePlayButton from "@/web/components/mobileapp/buttons/GooglePlayButton";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";

export default {
  name: "MobileAppInfoComponent",
  mixins: [LpConfigMixin],
  props: ["moduleData"],

  components: {
    Separator,
    AppleStoreButton,
    GooglePlayButton,
  },

  computed: {
    ...mapState(["event"]),

    commonModuleFields: () => LpConfigConstants.COMMON_MODULE_FIELDS,
    moduleFields: () => LpConfigConstants.MOBILE_APP_INFO_FIELDS,

    separatorVisible() {
      return !this.getFieldValueOrDefault(this.moduleData, this.commonModuleFields.SEPARATOR_DISABLED, false);
    },

    appName() {
      return this.getCustomTranslation(this.moduleData, this.moduleFields.APP_NAME, this.$t("mobile_app_info.meeting_application_app"));
    },

    isRzecommerce2024Event() {
      return this.event.id === 1643;
    },

    customDescription() {
      return this.getCustomTranslation(this.moduleData, this.moduleFields.DESCRIPTION, null);
    },

    previewImageUrl() {
      return this.getCustomTranslation(this.moduleData, this.moduleFields.PREVIEW_IMAGE, PreviewImage);
    },

    previewImageVisible() {
      return this.getFieldValueOrDefault(this.moduleData, this.moduleFields.PREVIEW_IMAGE_VISIBLE, true);
    },

    qrCodeImageUrl() {
      const qrcode = this.event.qrcode;
      return `https://qr.meetingapplication.com/?qr_url=${qrcode}`;
    },

    appleStoreUrl() {
      const appleStoreId = this.event.apple_shop_app_id;
      return `https://apps.apple.com/us/app/ma-2-0/id${appleStoreId}`;
    },

    googlePlayUrl() {
      const googlePlayId = this.event.google_shop_app_id;
      return `https://play.google.com/store/apps/details?id=${googlePlayId}`;
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  line-height: 27px;
}

.is-whitespaced {
  white-space: pre-line;
}

.has-text-primary.is-rzecommerce-2024-event {
  color: #5F23F0 !important;
}

.description-text {
  color: var(--text-color-primary);

  &.is-rzecommerce-2024-event {
    color: #000;
  }

  &.is-rzecommerce-2024-event-secondary {
    color: #5F23F0;
  }
}
</style>
