<template>
  <div class="description-box">
    <svg viewBox="0 0 286 21" width="286" fill="var(--event-tickets-background-color)" stroke="var(--event-tickets-border-color)" id="simulated" v-if="isCinemaViewType">
      <path d="M 20 0 h 246 a 20 20 0 0 0 20 20 v 81 a 20 20 0 0 1 -20 20 h -246 a 20 20 0 0 1 -20 -20 v -81 a 20 20 0 0 0 20 -20 Z"></path>
    </svg>
    <div class="description-content" :class="{ 'is-upgrade-2024-event': this.isUpgrade2024Event, 'is-rzecommerce-2024-event': isRzecommerce2024Event, 'primary': isRzecommerce2024Event && isRzecommerce2024PrimaryTicket }">
      <div class="description-list" :class="getCustomTicketDescriptionClass" v-html="description"></div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import {mapGetters, mapState} from "vuex";

export default {
  name: "EventTicketDescriptionView",

  props: {
    description: {
      type: String,
      required: true,
    },
    ticketViewType: {
      type: String,
      required: true,
    },
    ticketId: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapState(["event"]),
    ...mapGetters(["themeType"]),

    isCinemaViewType() {
      return this.ticketViewType === LpConfigConstants.EVENT_TICKET_VIEW_TYPES.CINEMA;
    },

    isUnityCommerceTheme() {
      return this.themeType === LpConfigConstants.THEME_TYPES.UNITY_COMMERCE;
    },

    isUpgrade2024Event() {
      return this.event.id === 1530;
    },

    isRzecommerce2024Event() {
      return this.event.id === 1643;
    },

    isRzecommerce2024PrimaryTicket() {
      return this.ticketId === 905;
    },

    getCustomTicketDescriptionClass() {
      return {
        'is-green-energy-tricity': this.ticketId === 580,
        'is-upgrade-2024-event': this.isUpgrade2024Event,
        'is-rzecommerce-2024-event': this.isRzecommerce2024Event,
        'primary': this.isRzecommerce2024Event && this.isRzecommerce2024PrimaryTicket,
      };
    },
  }
};
</script>

<style lang="scss" scoped>
.description-box {
  position: relative;
  width: 286px;
  height: auto;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
}

.description-content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 284px;
  padding: 13px 20px 20px 20px;
  margin-left: 1px;
  //background: white;
  box-shadow: -1px 0px 0px 0px var(--event-tickets-border-color), 1px 0px 0px 0px var(--event-tickets-border-color), 0px 1px 0px 0px var(--event-tickets-border-color);
  border-bottom-left-radius: var(--event-ticket-border-radius);
  border-bottom-right-radius: var(--event-ticket-border-radius);

  &.is-rzecommerce-2024-event {
    box-shadow: none;
    outline: 1px solid #5F23F0;
    background-color: #5F23F0;

    &.primary {
      background-color: var(--primary-color);
      outline: 1px solid var(--primary-color);
    }
  }
}

.description-list {
  flex: 1 1 auto;
}

.description-list ::v-deep li {
  color: var(--event-tickets-description-text-color);
  margin-top: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  padding-left: 26px;
  text-indent: -22px;
  line-height: 22px;
}

.description-list ::v-deep li:before {
  content: " ";
  background-color: var(--event-tickets-tick-color);
  display: inline-block;
  width: 22px;
  min-width: 22px;
  max-width: 22px;
  height: 11px;
  max-height: 11px;
  -webkit-mask-image: var(--event-ticket-li-image);
  mask-image: var(--event-ticket-li-image);
}

.description-list ::v-deep .item {
  display: flex;
  gap: 12px;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;

  & .check-container {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #fff;

    & .check-icon {
      background-color: var(--event-tickets-tick-color);
      display: block;
      width: 14px;
      height: 14px;
      -webkit-mask-image: var(--event-ticket-li-image);
      mask-image: var(--event-ticket-li-image);
      -webkit-mask-size: contain;
      mask-size: contain;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-position: center;
      mask-position: center;
      transform: translateX(2px) translateY(1px);
    }
  }
}

.description-list.is-rzecommerce-2024-event.primary ::v-deep .item {
  & .check-icon {
    background-color: var(--primary-color) !important;
  }
}

.description-list.is-green-energy-tricity ::v-deep li:before {
  background-color: #FBBA00;
}

.description-list.is-rzecommerce-2024-event  ::v-deep li:before {
  background-color: #fff;
}

.description-list.is-upgrade-2024-event ::v-deep li:before {
  background-color: #62BD9B;
}

.description-list.is-upgrade-2024-event ::v-deep li {
  color: #000000 !important;
}

.description-content.is-upgrade-2024-event {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.description-list ::v-deep li:not(:first-child) {
  margin-top: 11px;
}

.description-regular-background {
  background: var(--event-tickets-background-color);
  width: 286px;
}
</style>
