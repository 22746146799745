<template>
  <article class="module">
    <div class="container">
      <div class="rzecommerce-info">

        <div class="rzecommerce-info__col">
          <div class="rzecommerce-info__col-title">
            <h1 class="rzecommerce-title mb-4">
              {{ $t("custom.rzecommerce_info_title") }}
            </h1>
            <p>{{ $t("custom.rzecommerce_info_desc") }}</p>
          </div>

          <div class="rzecommerce-info__cards">
            <div class="rzecommerce-info__card">
              <h3>+1000</h3>
              <p>{{ $t("custom.rzecommerce_info_attendees") }}</p>
              <h3>+30</h3>
              <p>{{ $t("custom.rzecommerce_info_prelegents") }}</p>
              <h3>3</h3>
              <p>{{ $t("custom.rzecommerce_info_rooms") }}</p>
            </div>

            <div class="rzecommerce-info__card">
              <!-- <p class="bigger">Lorem Ipsum is <span class="primary-text-color bold">simply dummy</span> <span class="bold">text</span> of the printingand typesetting industry. <span class="bold">text ever since.</span></p> -->
              <p class="bigger">{{ $t("custom.rzecommerce_info_card_2_desc") }}</p>
            </div>
          </div>
        </div>

        <div class="rzecommerce-info__col">
          <h1 class="rzecommerce-title">
            {{ $t("custom.rzecommerce_info_organizers") }}
          </h1>
          <img class="rzecommerce-organizers-img" src="@/assets/custom/rzecommerce/organizers.png" alt="">

          <div class="rzecommerce-info__organizers-content">
            <img class="rzecommerce-logo-img" src="@/assets/custom/rzecommerce/vertical-logo.png" alt="">
            <div class="rzecommerce-info__organizers-quote">
              <p>{{ $t("custom.rzecommerce_info_organizers_quote") }}</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </article>
</template> 

<script>

import LpConfigMixin from "@/shared/mixins/LpConfigMixin";

export default {
  name: "RzecommerceInfoComponent",
  mixins: [LpConfigMixin],
};
</script>

<style scoped lang="scss">
.module {
  margin-bottom: 140px;

  @media(max-width: 575px) {
    margin-top: 60px;
  }
}

.rzecommerce-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  flex-wrap: wrap;
  row-gap: 40px;

  &__col {
    flex: 1;
    box-sizing: border-box;

    &-title {
      padding: 0 40px;

      @media(max-width: 678px) {
        padding: 0;
      }
    }

    & .rzecommerce-organizers-img {
      width: 100%;
    }
  }

  &__cards {
    display: flex;
    width: 100%;
    gap: 24px;
    justify-content: space-between;
    margin-top: 40px;

    @media(max-width: 678px) {
      flex-direction: column;
    }
  }

  &__card {
    background-color: var(--primary-color);
    border-radius: 24px;
    padding: 24px;
    box-sizing: border-box;
    min-width: 260px;
    height: min-content;

    & h3 {
      font-size: 42px;
      color: #fff;
      text-align: center;
      font-weight: 900;
      line-height: 100%;
      margin: 0;
      padding: 0;
    }

    & p {
      font-size: 18px;
      color: #fff;
      font-weight: 600;
      text-align: center;
      margin: 0;
      padding-bottom: 14px;
    }

    &:nth-child(2) {
      background-color: #5F23F0;
      transform: translateY(40px);

      & h3 {
        text-align: left;
        margin-bottom: 40px;
      }

      & p {
        text-align: left;
        font-weight: 400;
        &.bigger {
          font-size: 24px;
        }
      }

      & span.primary-text-color {
        color: var(--text-color-primary);
      }

      & span.bold {
        font-weight: 700;
      }

      @media(max-width: 678px) {
        margin-bottom: 40px;
      }
    }
  }

  &__organizers-content {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    & .rzecommerce-logo-img {
      width: 52px;
    }

    & span.author {
      display: block;
      margin-top: 8px;
      font-size: 24px;
      color: var(--text-color-primary);
    }
  }

  &__organizers-quote {
    max-width: 520px;
  }
}
</style>
